<template>
  <q-form ref="editForm">
    <c-card class="cardClassDetailForm" title="LBLDETAIL">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable&&!disabled&&isOld" 
            label="LBLREMOVE" 
            icon="delete" 
            @btnClicked="deleteSurvey" />
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="survey"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveSurvey"
            @btnCallback="saveSurveyCallback" />
          <c-btn 
            v-show="editable&&!disabled&&isOld" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="survey"
            mappingType="POST"
            label="위험평가 실시" 
            icon="check"
            @beforeAction="completeSurvey"
            @btnCallback="completeSurveyCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-field
            :required="true"
            :disabled="disabled||isOld"
            :editable="editable"
            :data="survey"
            deptValue="deptCd"
            type="dept_user"
            label="근로자"
            name="userId"
            v-model="survey.userId" 
            @dataChange="userChange"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            label="성별 / 만나이 (조사일 기점)"
            name="sexAge"
            v-model="sexAge"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-datepicker
            :required="true"
            :disabled="disabled"
            :editable="editable"
            default="today"
            label="조사일"
            name="surveyDate"
            v-model="survey.surveyDate"
            @datachange="calculationAge"
          />
        </div>
      </template>
    </c-card>
    <c-table
      class="q-mt-sm brain-cardiovascular-survey"
      title="증사조사표"
      :columns="grid.columns"
      :data="gridData"
      customDataTr
      gridHeightAuto
      hideHeader
      hideBottom
      isDense
      :isTitle="false"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <template v-slot:customDataTr="props">
        <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00005')}}
            </span>
            <div class="etc-info">
              <template v-if="bmi">
                <q-chip color="teal" text-color="white" icon="info" outline square>
                  {{bmi.riskFactors}}
                </q-chip>
                : {{bmi.riskFactorsResult}}
              </template>
              <template v-if="eGFR">
                <q-chip color="teal" text-color="white" icon="info" outline square>
                  {{eGFR.riskFactors}}
                </q-chip>
                : {{eGFR.riskFactorsResult}}
              </template>
            </div>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000001')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000002')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000004')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000014')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000013')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000031')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===2" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000005')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="4"
            codeGroupCd="PHYSICALLY_ACTIVE_OPTION_CD"
            type="radio"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000006')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="4"
            codeGroupCd="DRINK_CD"
            type="radio"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000007')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="4"
            codeGroupCd="SMOKE_CD"
            type="radio"
            @datachange="datachange"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===3" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000008')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000009')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000010')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000011')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000012')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
            @datachange="datachange"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000016')"
            :editable="editable&&!disabled"
            :required="true"
            :colspan="2"
            type="number"
            @datachange="datachange"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===4" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00001')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===5" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000017')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000018')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000019')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000020')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000021')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000022')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===6" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00010')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===7" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000023')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000024')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===8" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000025')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000026')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===9" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000027')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000028')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===10" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000029')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000030')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
        </q-tr>
      </template>
    </c-table>
  </q-form>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'brainCardiovascularInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',
      }),
    },
    survey: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 종합조사표 일련번호
        heaBrainCardiovascularStepCd: '',  // 진행상태
        userId: '',  // 근로자ID
        userName: '',  // 근로자명
        deptCd: '',  // 부서코드
        deptName: '',  // 부서명
        age: 0,  // 나이
        sexCd: '',  // 성별
        sexName: '',  // 성별
        birthDate: '', // 생년월일
        surveyDate: '',  // 평가일
        waistCircumference: '',  // 위험도_허리둘레
        bmi: '',  // 위험도_체질량 지수
        bloodPressure: '',  // 위험도_혈압
        hypertensionMedication: '',  // 위험도_고혈압 약 복용
        fastingBloodSugar: '',  // 위험도_공복 혈당
        diabetesMedication: '',  // 위험도_당뇨병 약 복용
        totalCholesterol: '',  // 위험도_총콜레스테롤
        egfr: '',  // 위험도_eGFR
        dipstickProteinuria: '',  // 위험도_Dipstick proteinuria 단백뇨
        smoking: '',  // 위험도_흡연상태
        physicallyActiveStatus: '',  // 위험도_신체 활동 여부
        regUserId: '',  //
        chgUserId: '',  //
        results: [], // 유해요인 평가결과 목록
        riskAssessment: {
          heaBrainCardiovascularRiskAssessmentId: '',  // 뇌심혈관계 위험평가 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          adjustedCompositeRelativeRisk: '',  // 조정 복합 상대위험도
          riskDevelopDisease: '',  // 발병위험도(10년)
          avgRiskDevelopDisease: '',  // 평균발병위험도(10년)
          riskOrthodonticDevelopment: '',  // 교정발병위험도(10년)
          reducedRiskDevelopDisease: '',  // 발병위험도 감소치
          cardiovascularAge: '',  // 심뇌혈관 나이
          correctedCardiovascularAge: '',  // 교정 심뇌혈관 나이
          integratedRiskClassificationCriteria: '',  // 통합형 위험도 분류기준
          /** 기타 정보 */
          complexRelativeRisk: '',  // 개인별 복합 상대위험도
          correctionCompositeRelativeRisk: '',  // 개인별 교정 복합 상대위험도
          regUserId: '',  //
          chgUserId: '',  //
        }, // 위험평가
        suitEval: {
          heaBrainCardiovascularWorkSuitEvalId: '',  // 뇌심혈관계 업무적합성 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          userId: '',  // 근로자ID (종합조사표 값)
          userName: '',  // 근로자명 (종합조사표 값)
          deptCd: '',  // 부서코드 (종합조사표 값)
          deptName: '',  // 부서명 (종합조사표 값)
          tasksPerformed: '',  // 수행업무
          diseaseReview: '',  // 임상증상 / 동반질환 검토의견
          habitsReview: '',  // 생활습관 / 검사항목 검토의견
          businessFactorsReview: '',  // 업무적요인 검토의견
          generalOpinionCd: '',  // 종합의견 - 결과
          generalOpinion: '',  // 종합의견 - 적합 이외인 경우 기술
          heaManagerId: '',  // 보건담당자 OR 의사
          regUserId: '',  //
          chgUserId: '',  //
        }, // 업무적합성 평가서
      }),
    },
    evalItems: {
      type: Array,
      default: () => [],
    },
    evalItemClasses: {
      type: Array,
      default: () => [],
    },
    riskPredictions: {
      type: Array,
      default: () => [],
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          { name: '1', field: '1', style: 'width:100px' },
          { name: '2', field: '2', style: 'width:150px' },
          { name: '3', field: '3', style: 'width:100px' },
          { name: '4', field: '4', style: 'width:150px' },
          { name: '5', field: '5', style: 'width:100px' },
          { name: '6', field: '6', style: 'width:150px' },
          { name: '7', field: '7', style: 'width:100px' },
          { name: '8', field: '8', style: 'width:150px' },
          { name: '9', field: '9', style: 'width:150px' },
          { name: '10', field: '10', style: 'width:150px' },
          { name: '11', field: '11', style: 'width:150px' },
          { name: '12', field: '12', style: 'width:150px' },
        ],
        data: [],
      },
      editable: true,
      isSave: true,
      isComplete: true,
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.isOld && this.survey.heaBrainCardiovascularStepCd !== 'HBCS000001'
    },
    sexAge() {
      return `${this.survey.age} / ${this.survey.sexName}`
    },
    gridData() {
      let data = [];
      for (let idx = 0; idx < 12; idx++) {
        data.push({})
      }
      return data;
    },
    evalItemTd() {
      return () => import(`${'@/pages/hea/bcs/brainCardiovascularInfoItemTd.vue'}`)
    },
    bmi() {
      return this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000003' })
    },
    eGFR() {
      return this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000015' })
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.hea.bcs.survey.insert.url
      this.deleteUrl = transactionConfig.hea.bcs.survey.delete.url
      this.completeUrl = transactionConfig.hea.bcs.riskAssessment.insert.url
      // code setting
      // list setting
    },
    userChange(userId, user) {
      this.$set(this.survey, 'userName', user.userName)
      this.$set(this.survey, 'deptCd', user.deptCd)
      this.$set(this.survey, 'deptName', user.deptName)
      this.$set(this.survey, 'sexCd', user.sexCd)
      this.$set(this.survey, 'sexName', user.sexName)
      this.$set(this.survey, 'birthDate', user.birthDate)

      this.calculationAge();

      /**
       * 건강검진 정보를 불러오는 로직은 하지 않음.
       * 이후 대동 프로젝트 진행 후 보건관리자의 의견을 수렴하여 개발이 필요
       */
    },
    calculationAge() {
      if (this.survey.surveyDate && this.survey.birthDate) {
        let age = 0;
        // 생일
        let birthDate = new Date(this.survey.birthDate)
        let birthYear = birthDate.getFullYear();
        let birthMonth = birthDate.getMonth();
        let birthDay = birthDate.getDate();
        // 기준일
        let standardData = new Date(this.survey.surveyDate)
        let standardYear = standardData.getFullYear();
        let standardMonth = standardData.getMonth();
        let standardDay = standardData.getDate();

        age = standardYear - birthYear;
        if (standardMonth < birthMonth || (standardMonth === birthMonth && birthDay < standardDay)) {
          age--;
        }
        this.$set(this.survey, 'age', age)
      } else {
        this.$set(this.survey, 'age', 0)
      }
    },
    evalItemClass(workSuitEvalItemClassCd) {
      let text = '';
      if (this.evalItemClasses && this.evalItemClasses.length > 0) {
        text = this.$_.find(this.evalItemClasses, { code: workSuitEvalItemClassCd }).codeName
      }
      return text;
    },
    getEvalItem(heaBrainCardiovascularEvalItemId) {
      return this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: heaBrainCardiovascularEvalItemId })
    },
    datachange() {
      /**
       * 처리항목
       *  - 위험도 예측 모형을 통한 위험도 산출
       *  - 체질량지수 (BMI) 계산 BCWI000003
       *  - eGFR (MDRD) 계산 BCWI000015
       */
      let height = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000001' });
      let weight = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000002' });
      let bmi = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000003' });
      let creatinineBlood = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000014' });
      let eGFR = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000015' });

      // 체질량지수 (BMI) 계산
      if (height.riskFactorsResult && weight.riskFactorsResult) {
        this.$set(bmi, 'riskFactorsResult', Math.round(Number(weight.riskFactorsResult) / Math.pow((Number(height.riskFactorsResult) / 100), 2) * 100) / 100)
        if (bmi.eidtFlag !== 'C') {
          bmi.editFlag = 'U'
          bmi.chgUserId = this.$store.getters.user.userId
        }
      }
      // eGFR (MDRD) 계산
      if (creatinineBlood.riskFactorsResult && this.survey.age > 0) {
        let c = this.survey.sexCd === 'M' ? 1 : 0.742 // 상수
        this.$set(eGFR, 'riskFactorsResult', Math.round(186 * Math.pow(Number(creatinineBlood.riskFactorsResult), -1.154) * Math.pow(this.survey.age, -0.203) * c * 100) / 100)
        if (eGFR.eidtFlag !== 'C') {
          eGFR.editFlag = 'U'
          eGFR.chgUserId = this.$store.getters.user.userId
        }
      }
    },
    minusRisk(risk1, risk2) {
      if (!risk2) {
        return risk1 - 1
      } else {
        return risk1 > risk2 ? risk1 - 1 : risk2 - 1
      }
    },
    setRisk() {
      /**
       * 처리항목
       *  - 위험도 예측 모형을 통한 위험도 산출
       *  - 체질량지수 (BMI) 계산 BCWI000003
       *  - eGFR (MDRD) 계산 BCWI000015
       *  
       * 위험도
       *  - 체질량지수 bmi
       *  - 허리둘레 waistCircumference
       *  - 혈압 bloodPressure
       *  - 고혈압 약 복용 hypertensionMedication
       *  - 공복 혈당 fastingBloodSugar
       *  - 당뇨병 약 복용 diabetesMedication
       *  - 총콜레스테롤 totalCholesterol
       *  - eGFR egfr
       *  - Dipstick proteinuria 단백뇨 dipstickProteinuria
       *  - 흡연상태 smoking
       *  - 신체 활동 여부 physicallyActiveStatus
       * 
       * 기타정보
       *  - 개인별 복합 상대위험도
       *  - 개인별 교정 복합 상대위험도
       * 
       * 평가기준 & 목표상태
       *  - BMI & 허리둘레
       *  - 혈압
       *  - 공복혈당
       *  - 총콜레스테롤
       *  - 음주
       *  - 흡연
       *  - 신체활동
       */
      return new Promise(resolve => {
        let height = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000001' });
        let weight = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000002' });
        let bmi = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000003' });
        let waistCircumference = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000004' });
        let physicalActivity = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000005' });
        let drinking = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000006' });
        let smoking = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000007' });
        let bloodPressureContraction = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000008' });
        let bloodPressureExpansion = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000009' });
        let hypertensionMedication = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000010' });
        let fastingBloodSugar = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000011' });
        let diabetesMedication = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000012' });
        let totalCholesterol = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000013' });
        let ldlCholesterol = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000031' });
        let eGFR = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000015' });
        let dipstickProteinuria = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000016' });
        // 체질량지수 위험도
        if (bmi.riskFactorsResult) {
          let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000001' })
          let riskFactorsResult = Number(bmi.riskFactorsResult);
          let risk = null;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if (riskFactorsResult >= riskPrediction.conditionFrom1
              && riskFactorsResult < riskPrediction.conditionTo1) {
                risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
                return false;
            }
          })
          this.$set(this.survey, 'bmi', risk)
        }
        // 허리둘레 위험도
        if (waistCircumference.riskFactorsResult) {
          let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000005' })
          let riskFactorsResult = Number(waistCircumference.riskFactorsResult);
          let risk = null;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if (this.survey.sexCd === 'M'
              && riskFactorsResult >= riskPrediction.conditionFrom1
              && riskFactorsResult < riskPrediction.conditionTo1) {
                risk = riskPrediction.riskMale
                return false;
            } else if (this.survey.sexCd === 'F'
              && riskFactorsResult >= riskPrediction.conditionFrom1
              && riskFactorsResult < riskPrediction.conditionTo1) {
                risk = riskPrediction.riskFemale
                return false;
            }
          })
          
          this.$set(this.survey, 'waistCircumference', risk)
        }
        // 흡연 위험도
        if (smoking.riskFactorsResult) {
          let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000010' })
          let risk = null;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if (smoking.riskFactorsResult === riskPrediction.conditionText) {
              risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
              return false;
            }
          })
          this.$set(this.survey, 'smoking', risk)
        }
        // 혈압 위험도
        if (bloodPressureContraction.riskFactorsResult && bloodPressureExpansion.riskFactorsResult) {
          let riskPredictions = this.$_.reverse(this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000015' }))
          let riskFactorsResult1 = Number(bloodPressureContraction.riskFactorsResult);
          let riskFactorsResult2 = Number(bloodPressureExpansion.riskFactorsResult);
          let risk = null;
          let idx = 0;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if (idx === 3) {
              if (riskFactorsResult1 < riskPrediction.conditionTo1 && riskFactorsResult2 < riskPrediction.conditionTo2) {
                risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
                return false;
              }
            } else if (idx === 0) {
              if (riskFactorsResult1 >= riskPrediction.conditionFrom1 && riskFactorsResult2 >= riskPrediction.conditionFrom2) {
                risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
                return false;
              }
            } else {
              if ((riskFactorsResult1 >= riskPrediction.conditionFrom1 && riskFactorsResult1 < riskPrediction.conditionTo1)
                || (riskFactorsResult2 >= riskPrediction.conditionFrom2 && riskFactorsResult2 < riskPrediction.conditionTo2)) {
                risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
                return false;
              }
            }
            idx++;
          })
          this.$set(this.survey, 'bloodPressure', risk)
        }
        // 혈압약 위험도
        if (hypertensionMedication.riskFactorsResult) {
          let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000020' })
          let risk = null;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if (hypertensionMedication.riskFactorsResult === riskPrediction.conditionText) {
              risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
              return false;
            }
          })
          this.$set(this.survey, 'hypertensionMedication', risk)
        }
        // 신체 활동 위험도
        if (physicalActivity.riskFactorsResult) {
          let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000025' })
          let risk = null;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if ((physicalActivity.riskFactorsResult === 'PAO0000010' || physicalActivity.riskFactorsResult === 'PAO0000015')
              && riskPrediction.conditionFrom1 === 3) {
              risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
              return false;
            } else if (physicalActivity.riskFactorsResult !== 'PAO0000010' && physicalActivity.riskFactorsResult !== 'PAO0000015'
              && riskPrediction.conditionTo1 === 3) {
              risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
              return false;
            }
          })
          this.$set(this.survey, 'physicallyActiveStatus', risk)
        }
        // 공복 혈당 위험도
        if (fastingBloodSugar.riskFactorsResult) {
          let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000030' })
          let riskFactorsResult = Number(fastingBloodSugar.riskFactorsResult);
          let risk = null;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if (riskFactorsResult >= riskPrediction.conditionFrom1
              && riskFactorsResult < riskPrediction.conditionTo1) {
                risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
                return false;
            }
          })
          this.$set(this.survey, 'fastingBloodSugar', risk)
        }
        // 당뇨병 약 복용 위험도
        if (diabetesMedication.riskFactorsResult) {
          let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000035' })
          let risk = null;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if (diabetesMedication.riskFactorsResult === riskPrediction.conditionText) {
              risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
              return false;
            }
          })
          this.$set(this.survey, 'diabetesMedication', risk)
        }
        // 총콜레스테롤 위험도
        if (totalCholesterol.riskFactorsResult) {
          let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000040' })
          let riskFactorsResult = Number(totalCholesterol.riskFactorsResult);
          let risk = null;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if (riskFactorsResult >= riskPrediction.conditionFrom1
              && riskFactorsResult < riskPrediction.conditionTo1) {
                risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
                return false;
            }
          })
          this.$set(this.survey, 'totalCholesterol', risk)
        }
        // eGFR 위험도
        if (eGFR.riskFactorsResult) {
          let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000045' })
          let riskFactorsResult = Number(eGFR.riskFactorsResult);
          let risk = null;
          this.$_.forEach(riskPredictions, riskPrediction => {
            if (riskFactorsResult >= riskPrediction.conditionFrom1
              && riskFactorsResult < riskPrediction.conditionTo1) {
                risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
                return false;
            }
          })
          this.$set(this.survey, 'egfr', risk)
        }
        // Dipstick proteinuria 단백뇨 위험도
        let riskPredictions = this.$_.filter(this.riskPredictions, { heaRiskPredictionModelCd: 'RPM0000050' })
        let riskFactorsResult = dipstickProteinuria.riskFactorsResult ? Number(dipstickProteinuria.riskFactorsResult) : 0;
        let risk = null;
        this.$_.forEach(riskPredictions, riskPrediction => {
          if (riskFactorsResult >= riskPrediction.conditionFrom1
            && riskFactorsResult < riskPrediction.conditionTo1) {
              risk = this.survey.sexCd === 'M' ? riskPrediction.riskMale : riskPrediction.riskFemale
              return false;
          }
        })
        this.$set(this.survey, 'dipstickProteinuria', risk)

        if (!this.survey.riskAssessment) {
          this.$set(this.survey, 'riskAssessment', {
            heaBrainCardiovascularRiskAssessmentId: '',  // 뇌심혈관계 위험평가 일련번호
            heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
            adjustedCompositeRelativeRisk: '',  // 조정 복합 상대위험도
            riskDevelopDisease: '',  // 발병위험도(10년)
            avgRiskDevelopDisease: '',  // 평균발병위험도(10년)
            riskOrthodonticDevelopment: '',  // 교정발병위험도(10년)
            reducedRiskDevelopDisease: '',  // 발병위험도 감소치
            cardiovascularAge: '',  // 심뇌혈관 나이
            correctedCardiovascularAge: '',  // 교정 심뇌혈관 나이
            integratedRiskClassificationCriteria: '',  // 통합형 위험도 분류기준
            /** 기타 정보 */
            complexRelativeRisk: '',  // 개인별 복합 상대위험도
            correctionCompositeRelativeRisk: '',  // 개인별 교정 복합 상대위험도
            regUserId: '',  //
            chgUserId: '',  //
          })
        }
        // 개인별 복합 상대위험도
        if (this.survey.waistCircumference > 0
          && this.survey.bmi > 0
          && this.survey.bloodPressure > 0
          && this.survey.hypertensionMedication > 0
          && this.survey.fastingBloodSugar > 0
          && this.survey.diabetesMedication > 0
          && this.survey.totalCholesterol > 0
          && this.survey.egfr > 0
          && this.survey.dipstickProteinuria > 0
          && this.survey.smoking > 0
          && this.survey.physicallyActiveStatus > 0
        ) {
          let risk = 1 
            + this.minusRisk(this.survey.waistCircumference, this.survey.bmi)
            + this.minusRisk(this.survey.bloodPressure)
            + this.minusRisk(this.survey.hypertensionMedication)
            + this.minusRisk(this.survey.fastingBloodSugar)
            + this.minusRisk(this.survey.diabetesMedication)
            + this.minusRisk(this.survey.totalCholesterol)
            + this.minusRisk(this.survey.egfr, this.survey.dipstickProteinuria)
            + this.minusRisk(this.survey.smoking)
            + this.minusRisk(this.survey.physicallyActiveStatus)
          this.$set(this.survey.riskAssessment, 'complexRelativeRisk', risk)
        }
        // 개인별 교정 복합 상대위험도
        if (this.survey.smoking > 0
          && this.survey.hypertensionMedication > 0
          && this.survey.diabetesMedication > 0
          && this.survey.egfr > 0
          && this.survey.dipstickProteinuria > 0
        ) {
          let risk = 1 
            + this.minusRisk(this.survey.hypertensionMedication)
            + this.minusRisk(this.survey.diabetesMedication)
            + this.minusRisk(this.survey.egfr, this.survey.dipstickProteinuria)
            + this.minusRisk(this.survey.smoking)
          this.$set(this.survey.riskAssessment, 'correctionCompositeRelativeRisk', risk)
        }

        // 평가기준 및 목표 제시 
        if (height.riskFactorsResult && bmi.riskFactorsResult && this.survey.waistCircumference) {
          let heightRisk = Number(height.riskFactorsResult);
          let bmiRisk = Number(bmi.riskFactorsResult);
          let waistRisk = Number(this.survey.waistCircumference);
          if (bmiRisk >= 18.5 && bmiRisk < 23 && waistRisk === 1) {
            this.$set(weight, 'healthConditionCd', 'HC00000001')
            this.$set(waistCircumference, 'healthConditionCd', 'HC00000001')
          } else if ((bmiRisk >= 25 && bmiRisk < 30 && waistRisk !== 1) || bmiRisk >= 30) {
            this.$set(weight, 'healthConditionCd', 'HC00000010')
            this.$set(waistCircumference, 'healthConditionCd', 'HC00000010')
          } else {
            this.$set(weight, 'healthConditionCd', 'HC00000005')
            this.$set(waistCircumference, 'healthConditionCd', 'HC00000005')
          }
          this.$set(weight, 'riskFactorsGoalResult', (Math.round((Math.pow(heightRisk / 100, 2) * 23) * 100) / 100) + 'kg, BMI 23기준 정상체중')
          this.$set(waistCircumference, 'riskFactorsGoalResult', '남성 90cm 미만 / 여성 85cm 미만')
        }

        if (bloodPressureContraction.riskFactorsResult && this.survey.hypertensionMedication) {
          let hypertensionMedicationRisk = Number(this.survey.hypertensionMedication);
          let riskFactorsResult1 = Number(bloodPressureContraction.riskFactorsResult);
          let riskFactorsResult2 = Number(bloodPressureExpansion.riskFactorsResult);
          if (hypertensionMedicationRisk === 1) {
            if (riskFactorsResult1 < 140 && riskFactorsResult2 < 90) {
              this.$set(bloodPressureContraction, 'healthConditionCd', 'HC00000005')
              this.$set(bloodPressureExpansion, 'healthConditionCd', 'HC00000005')
            } else {
              this.$set(bloodPressureContraction, 'healthConditionCd', 'HC00000010')
              this.$set(bloodPressureExpansion, 'healthConditionCd', 'HC00000010')
            }
          } else {
            if (riskFactorsResult1 < 120 && riskFactorsResult2 < 80) {
              this.$set(bloodPressureContraction, 'healthConditionCd', 'HC00000001')
              this.$set(bloodPressureExpansion, 'healthConditionCd', 'HC00000001')
            } else if ((riskFactorsResult1 < 140 && riskFactorsResult1 >= 120)
              || (riskFactorsResult2 < 90 && riskFactorsResult2 >= 80)
            ) {
              this.$set(bloodPressureContraction, 'healthConditionCd', 'HC00000005')
              this.$set(bloodPressureExpansion, 'healthConditionCd', 'HC00000005')
            } else {
              this.$set(bloodPressureContraction, 'healthConditionCd', 'HC00000010')
              this.$set(bloodPressureExpansion, 'healthConditionCd', 'HC00000010')
            }
          }
          this.$set(bloodPressureContraction, 'riskFactorsGoalResult', '120/80mmHg 미만')
          this.$set(bloodPressureExpansion, 'riskFactorsGoalResult', '120/80mmHg 미만')
        }

        if (fastingBloodSugar.riskFactorsResult && this.survey.diabetesMedication) {
          let diabetesMedicationRisk = Number(this.survey.diabetesMedication);
          let riskFactorsResult = Number(fastingBloodSugar.riskFactorsResult);
          if (diabetesMedicationRisk === 1) {
            if (riskFactorsResult < 126) {
              this.$set(fastingBloodSugar, 'healthConditionCd', 'HC00000005')
            } else {
              this.$set(fastingBloodSugar, 'healthConditionCd', 'HC00000010')
            }
          } else {
            if (riskFactorsResult < 100) {
              this.$set(fastingBloodSugar, 'healthConditionCd', 'HC00000001')
            } else if (riskFactorsResult < 126 && riskFactorsResult >= 100) {
              this.$set(fastingBloodSugar, 'healthConditionCd', 'HC00000005')
            } else {
              this.$set(fastingBloodSugar, 'healthConditionCd', 'HC00000010')
            }
          }
          this.$set(fastingBloodSugar, 'riskFactorsGoalResult', '100mg/dL 미만')
        }
        
        if (totalCholesterol.riskFactorsResult && ldlCholesterol.riskFactorsResult && this.survey.diabetesMedication) {
          let totalRisk = Number(totalCholesterol.riskFactorsResult);
          let ldlRisk = Number(ldlCholesterol.riskFactorsResult);
          let diabetesMedicationRisk = Number(this.survey.diabetesMedication);
          if (totalRisk < 200) {
            if ((diabetesMedicationRisk === 1 && ldlRisk < 130)
              || (diabetesMedicationRisk !== 1 && ldlRisk < 100)
            ) {
              this.$set(totalCholesterol, 'healthConditionCd', 'HC00000001')
            } else if ((diabetesMedicationRisk === 1 && ldlRisk >= 160)
              || (diabetesMedicationRisk !== 1 && ldlRisk >= 100)
            ) {
              this.$set(totalCholesterol, 'healthConditionCd', 'HC00000010')
            } else {
              this.$set(totalCholesterol, 'healthConditionCd', 'HC00000005')
            }
          } else {
            if ((diabetesMedicationRisk === 1 && ldlRisk < 160)
              || (diabetesMedicationRisk !== 1 && ldlRisk < 100)
            ) {
              this.$set(totalCholesterol, 'healthConditionCd', 'HC00000005')
            } else if ((diabetesMedicationRisk === 1 && ldlRisk >= 160)
              || (diabetesMedicationRisk !== 1 && ldlRisk >= 100)
            ) {
              this.$set(totalCholesterol, 'healthConditionCd', 'HC00000010')
            }
          }
          this.$set(totalCholesterol, 'riskFactorsGoalResult', '총콜레스테롤 : 200mg/dL 미만\nLDL콜레스테롤 : 130mg/dL 미만 (당뇨병 환자 100mg/dL 미만)')
        }

        if (drinking.riskFactorsResult && this.survey.sexCd && this.survey.age) {
          if (this.survey.sexCd === 'M') {
            if (this.survey.age < 65) {
              if (drinking.riskFactorsResult === 'DC00000015' || drinking.riskFactorsResult === 'DC00000020') {
                this.$set(drinking, 'healthConditionCd', 'HC00000005')
              } else if (drinking.riskFactorsResult === 'DC00000025') {
                this.$set(drinking, 'healthConditionCd', 'HC00000010')
              } else {
                this.$set(drinking, 'healthConditionCd', 'HC00000001')
              }
            } else {
              if (drinking.riskFactorsResult === 'DC00000001' || drinking.riskFactorsResult === 'DC00000005') {
                this.$set(drinking, 'healthConditionCd', 'HC00000001')
              } else if (drinking.riskFactorsResult === 'DC00000020' || drinking.riskFactorsResult === 'DC00000025') {
                this.$set(drinking, 'healthConditionCd', 'HC00000010')
              } else {
                this.$set(drinking, 'healthConditionCd', 'HC00000005')
              }
            }
          } else {
            if (this.survey.age < 65) {
              if (drinking.riskFactorsResult === 'DC00000001' || drinking.riskFactorsResult === 'DC00000005') {
                this.$set(drinking, 'healthConditionCd', 'HC00000001')
              } else if (drinking.riskFactorsResult === 'DC00000020' || drinking.riskFactorsResult === 'DC00000025') {
                this.$set(drinking, 'healthConditionCd', 'HC00000010')
              } else {
                this.$set(drinking, 'healthConditionCd', 'HC00000005')
              }
            } else {
              if (drinking.riskFactorsResult === 'DC00000001' || drinking.riskFactorsResult === 'DC00000005') {
                this.$set(drinking, 'healthConditionCd', 'HC00000001')
              } else {
                this.$set(drinking, 'healthConditionCd', 'HC00000010')
              }
            }
          }
          this.$set(drinking, 'riskFactorsGoalResult', '남성 < 65세 하루 2잔 이하\n남성 >= 65세 하루 1잔 이하\n여성 하루 1잔 이하')
        }

        if (smoking.riskFactorsResult) {
          if (smoking.riskFactorsResult === 'SC00000001') {
            this.$set(smoking, 'healthConditionCd', 'HC00000001')
            this.$set(smoking, 'riskFactorsGoalResult', '비흡연')
          } else if (smoking.riskFactorsResult === 'SC00000005') {
            this.$set(smoking, 'healthConditionCd', 'HC00000005')
            this.$set(smoking, 'riskFactorsGoalResult', '금연 유지')
          } else {
            this.$set(smoking, 'healthConditionCd', 'HC00000010')
            this.$set(smoking, 'riskFactorsGoalResult', '금연')
          }
        }

        if (physicalActivity.riskFactorsResult) {
          if (physicalActivity.riskFactorsResult === 'PAO0000010') {
            this.$set(physicalActivity, 'healthConditionCd', 'HC00000005')
          } else if (physicalActivity.riskFactorsResult === 'PAO0000015') {
            this.$set(physicalActivity, 'healthConditionCd', 'HC00000001')
          } else {
            this.$set(physicalActivity, 'healthConditionCd', 'HC00000010')
          }
          this.$set(physicalActivity, 'riskFactorsGoalResult', '')
        }
        resolve(true);
      });
    },
    saveValue() {
      return new Promise(resolve => {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.survey.regUserId = this.$store.getters.user.userId
                this.survey.chgUserId = this.$store.getters.user.userId

                this.isSave = !this.isSave
                resolve(true);
              },
              // 취소 callback 함수
              cancelCallback: () => {
                resolve(true);
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
            resolve(true);
          }
        });
      });
    },
    saveSurvey() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.hea.bcs.survey.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.hea.bcs.survey.insert.url
      }

      let promises = [
        {
          func: this.setRisk,
        },
        {
          func: this.saveValue
        },
      ];
      this.$comm.orderedPromise(promises);
    },
    saveSurveyCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'heaBrainCardiovascularSurveyId', _result.data)
      this.$emit('getDetail')
    },
    validComplete() {
      return new Promise((resolve, reject) => {
        if (!this.survey.riskAssessment.complexRelativeRisk) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '개인별 복합 상대위험도가 산출되지 않았습니다.\n입력항목을 확인해주세요.',
            type: 'warning', // success / info / warning / error
          });
          reject();
        } else if (!this.survey.riskAssessment.correctionCompositeRelativeRisk) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '개인별 교정 복합 상대위험도가 산출되지 않았습니다.\n입력항목을 확인해주세요.',
            type: 'warning', // success / info / warning / error
          });
          reject();
        } else {
          resolve(true);
        }
      });
    },
    completeValue() {
      return new Promise(resolve => {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '위험평가 실시하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.survey.regUserId = this.$store.getters.user.userId
                this.survey.chgUserId = this.$store.getters.user.userId
                this.survey.riskAssessment.regUserId = this.$store.getters.user.userId
                this.survey.riskAssessment.chgUserId = this.$store.getters.user.userId

                this.isComplete = !this.isComplete
                resolve(true);
              },
              // 취소 callback 함수
              cancelCallback: () => {
                resolve(true);
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
            resolve(true);
          }
        });
      });
    },
    completeSurvey() {      
      /**
       * 1. 개인별 복합 상대위험도가 계산되었는지?
       * 2. 개인별 교정 복합 상대위험도가 계산되었는지?
       */
      let promises = [
        {
          func: this.setRisk,
        },
        {
          func: this.validComplete,
        },
        {
          func: this.completeValue
        },
      ];
      this.$comm.orderedPromise(promises);
    },
    completeSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
    },
    deleteSurvey() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.heaBrainCardiovascularSurveyId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
<style lang="sass">
.brain-cardiovascular-survey
  .th-td-style
    vertical-align: middle !important
  .th-style
    background: #EEEEEE !important
    font-weight: 700
    width: 100%
  .td-style
    width: 16.6%
  .etc-info
    display: inline-block
    float: right
</style>